// la liste des listners que l'on utilise dans le code du composant
const PAGE_MENU_LISTNERS = {
  CLICK: "click",
  RESIZE: "resize",
  FOCUS: "focus",
  BLUR: "blur"
};

// la liste des éléments que l'on utilise dans le code du composant
const PAGE_MENU_ELEMENTS = {
  LINK: "a",
  MORE_ITEMS: "li",
  MORE_ITEMS_LIST: "ul"
};

// la liste des classes que l'on utilise dans le code du composant
const PAGE_MENU_CLASSES = {
  ITEM_ACTIVE: "o-active",
  ITEM_ACTIVE_COLOR: "text-primary",
  ITEM_INLINE: "ob1-nav-inline",
  MORE_ITEMS: "ob1-more-items",
  MORE_ITEMS_HIDDEN: "d-none",
  NAV_LINK: "nav-link",
  MORE_ITEMS_LIST: "ob1-more-items-list",
  MORE_ITEMS_LIST_HIDDEN: "hidden",
  CURSOR_INITIAL: "ob1-cursor-initial",
  TEXT_DARK: "text-dark"
};

// la liste des attributs que l'on utilise dans le code du composant
const ATTRIBUTES = {
  HREF: "href",
  HREF_VALUE: "#"
};

// la liste des textes que l'on utilise dans le code du composant
const PAGE_MENU_TEXTS = {
  MORE_ITEMS_NAME: "Plus"
};

// la liste des sélecteurs que l'on utilise dans le code du composant
const PAGE_MENU_SELECTORS = {
  CONTAINER: ".nav.o-nav-light",
  ALL_ITEMS: ".nav.o-nav-light .nav-item",
  FIRST_LINK_ITEM: ".nav.o-nav-light .nav-item .nav-link",
  MORE_ITEMS_LIST: ".ob1-more-items-list",
  NAV_ITEM: ".nav-item",
  NAV_LINK: ".nav-link",
  MORE_ITEMS_LIST_LINK: ".ob1-more-items-list .nav-item .nav-link"
};

class PageMenu {

  /**
   * Constructeur
   * @param {HTMLElement} pageMenu - L'élément représentant le menu de navigation
   */
  constructor(pageMenu) {

    this.pageMenu = pageMenu;

    // On initialise l'élément "Plus" qui contiendra les items ne contenant pas sur une seule ligne
    this.moreItems = this.createMoreItemsElement();

    // Liste des items présents dans l'élément "Plus"
    this.moreItemsList = this.moreItems.querySelector(PAGE_MENU_SELECTORS.MORE_ITEMS_LIST);

    // On sélectionne par défaut le premier élément
    pageMenu.querySelector(PAGE_MENU_SELECTORS.FIRST_LINK_ITEM).classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);


    // On met en place des listners permettant la mise en évidence (soulignement + couleur Orange) de l'item sélectionné
    this.setActiveItemManagement();

    // Si on est sur un device n'utilisant pas de souris
    if (window.matchMedia("(hover:none)").matches) {

      // On affiche les items sur une seule ligne afin que l'utilisateur puisse swipper
      document.querySelector(PAGE_MENU_SELECTORS.CONTAINER).classList.add(PAGE_MENU_CLASSES.ITEM_INLINE);
      for (const item of document.querySelectorAll(PAGE_MENU_SELECTORS.ALL_ITEMS)) {
        item.classList.add(PAGE_MENU_CLASSES.ITEM_INLINE);
      }
    } else {

      // On appelle la méthode de redimmensionnement du menu afin d'ajouter/retirer d'éventuels items dans l'élément "Plus"
      this.resize();

      // On crée un listner pour gérer l'ajout/retrait d'items dans l'élément "Plus" lors d'un redimensionnement de la page
      window.addEventListener(PAGE_MENU_LISTNERS.RESIZE, () => {
        this.resize();
      });
    }
  }

  /**
   * Permet de gérer le redimensionnement du composant
   */
  resize() {

    // Largeur du composant
    const containerWidth = this.pageMenu.offsetWidth;

    // On supprime la classe "hidden" afin de pouvoir accéder aux tailles des items présents dans l'élément "Plus" (on la repositionnera à la fin des calculs)
    this.showOrHideMoreItemsList(true);

    // Tous les items moins l'élément "Plus"
    const childsNumber = this.pageMenu.children.length - 1;

    // La marge de sécurité est déterminée à partir de la taille du + grand item du menu afin qu'aucun item ne soit affiché sur une 2ème ligne
    let securityMargin = this.getSecurityMargin();

    // Initilisation de variables
    let childsWidth = 0;
    let indexChildToMove = 0;
    let indexChildToMoveFound = false;
    let removeMoreItemsListElement = false;

    // On cherche l'index de l'enfant à partir duquel on va effectuer la bascule dans la liste "Plus"
    for (let indexChild = 0; indexChild < childsNumber; indexChild++) {
      let currentChildWidth = this.pageMenu.children[ indexChild ].offsetWidth;

      childsWidth += currentChildWidth;

      if (!indexChildToMoveFound && ((childsWidth + securityMargin) > containerWidth)) {
        indexChildToMove = indexChild;
        indexChildToMoveFound = true;
      }
    }

    // Au contraire, on vérifie si on peut sortir des items de la liste "Plus"
    let moreItemsListChildsWidth = this.moreItemsList.offsetWidth;
    let indexMoreItemsListChildToMove = 0;
    if (this.moreItemsList.childElementCount > 0) {
      for (let indexMoreItemsListChild = 0; indexMoreItemsListChild < this.moreItemsList.childElementCount;
                                            indexMoreItemsListChild++) {
        if (!indexChildToMoveFound && (containerWidth > (childsWidth + moreItemsListChildsWidth + securityMargin))) {
          indexMoreItemsListChildToMove = indexMoreItemsListChild;
          removeMoreItemsListElement = true;
        }
      }
    }

    // On fait la bascule en fonction des cas identifiés précédemment
    if (indexChildToMoveFound) {
      for (let indexChild = this.pageMenu.children.length - 1; indexChild >= 0; indexChild--) {

        // On déplace les éléments dans la liste "Plus"
        if ((indexChildToMove > 0) && (indexChild >= indexChildToMove) &&
          this.pageMenu.children[ indexChild ] !== this.moreItems) {
          let elementToMove = this.pageMenu.children[ indexChild ];

          // Si au moins 1 item est déjà présent dans "Plus" , on insère le nouvel item au début de la liste
          if (this.moreItemsList.childElementCount > 0) {
            this.moreItemsList.insertBefore(elementToMove,
                                            this.moreItemsList.querySelector(PAGE_MENU_SELECTORS.NAV_ITEM));

          // Sinon on ajoute l'item dans la liste vide
          } else {
            this.moreItemsList.appendChild(elementToMove);
          }

          // On change les styles de l'élément déplacé et de l'item "Plus"
          let elementToMoveLink = elementToMove.querySelector(PAGE_MENU_SELECTORS.NAV_LINK);
          if (elementToMoveLink.classList.contains(PAGE_MENU_CLASSES.ITEM_ACTIVE) ||
            elementToMoveLink.classList.contains(PAGE_MENU_CLASSES.ITEM_ACTIVE_COLOR)) {
            elementToMoveLink.classList.remove(PAGE_MENU_CLASSES.ITEM_ACTIVE);
            elementToMoveLink.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE_COLOR);
            this.moreItems.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
          }
        }
      }
    } else if (removeMoreItemsListElement) {

      // On sort le 1er élément de la liste d'items présents dans l'élément "Plus" pour le placer entre le dernier item en dehors et l'élément "Plus"
      let index = 0;
      while (index <= indexMoreItemsListChildToMove) {
        let elementToMoveLink = this.moreItemsList.querySelector(PAGE_MENU_SELECTORS.NAV_LINK);
        if (elementToMoveLink.classList.contains(PAGE_MENU_CLASSES.ITEM_ACTIVE_COLOR)) {
          this.moreItems.classList.remove(PAGE_MENU_CLASSES.ITEM_ACTIVE);
          elementToMoveLink.classList.remove(PAGE_MENU_CLASSES.ITEM_ACTIVE_COLOR);
          elementToMoveLink.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
        }
        this.pageMenu.insertBefore(this.moreItemsList.children[ 0 ],
          this.pageMenu.children[ this.pageMenu.childElementCount - 1 ]);
        index++;
      }
    }

    // On centre l'élément moreItemsList en dessous du moreItems
    this.computeMoreItemsListMarginLeft();

    // On masque la liste des items présents dans l'élément "Plus" pour qu'ils ne soient visibles qu'au suvol de ce dernier
    this.showOrHideMoreItemsList(false);

    // On masque l'élément "Plus" s'il est vide
    this.showOrHideMoreItems();
  }

  /**
   * Permet de connaître la marge de sécurité à prendre en compte lors d'un ajout/suppresion d'item dans la liste "Plus"
   * @return {number} - La marge de sécurité
   */
  getSecurityMargin() {
    let securityMargin = 0;
    for (const item in this.pageMenu.children) {
      if (this.pageMenu.children[ item ].offsetWidth > securityMargin) {
        securityMargin = this.pageMenu.children[ item ].offsetWidth;
      }
    }

    return securityMargin;
  }

  /**
   * Crée l'élément appelé "Plus" et contenant les items du menu ne pouvant pas être affichés sur une seule ligne
   * @return {HTMLElement} - L'élément "Plus"
   */
  createMoreItemsElement() {
    let moreItemsElement = document.createElement(PAGE_MENU_ELEMENTS.MORE_ITEMS);
    moreItemsElement.classList.add(PAGE_MENU_CLASSES.MORE_ITEMS);
    moreItemsElement.classList.add(PAGE_MENU_CLASSES.NAV_LINK);
    let linkMoreItemsElement = document.createElement(PAGE_MENU_ELEMENTS.LINK);
    linkMoreItemsElement.setAttribute(ATTRIBUTES.HREF, ATTRIBUTES.HREF_VALUE);
    linkMoreItemsElement.classList.add(PAGE_MENU_CLASSES.TEXT_DARK);
    linkMoreItemsElement.classList.add(PAGE_MENU_CLASSES.CURSOR_INITIAL);
    linkMoreItemsElement.innerText = PAGE_MENU_TEXTS.MORE_ITEMS_NAME;
    moreItemsElement.appendChild(linkMoreItemsElement);
    let moreItemsList = document.createElement(PAGE_MENU_ELEMENTS.MORE_ITEMS_LIST);
    moreItemsList.classList.add(PAGE_MENU_CLASSES.MORE_ITEMS_LIST);
    moreItemsElement.appendChild(linkMoreItemsElement);
    moreItemsElement.appendChild(moreItemsList);

    this.pageMenu.appendChild(moreItemsElement);

    return moreItemsElement;
  }

  /**
   * Ajoute une marge gauche négative à la liste des items présents dans l'élément "Plus" pour que la liste apparaisse de manière centrée en dessous du libellé "Plus"
   */
  computeMoreItemsListMarginLeft() {

    if (this.moreItemsList.childElementCount > 0) {

      // Largeur de l'élement moreItemsList
      const moreItemsListWidth = this.moreItemsList.offsetWidth;

      // Marge appliquée par défaut sur les links du moreItemsList
      const navLinkMoreItems = document.querySelector(PAGE_MENU_SELECTORS.MORE_ITEMS_LIST_LINK);
      const navLinkMoreItemsMarginLeft = parseInt(getComputedStyle(navLinkMoreItems).marginLeft);

      // On calcule une marge négative à appliquer sur l'élement moreItemsList afin que ce dernier soit centré en dessous du libellé du moreItems
      const moreItemsListMarginLeft = "-" + ((moreItemsListWidth / 2) - navLinkMoreItemsMarginLeft);

      this.moreItemsList.style.marginLeft = moreItemsListMarginLeft + "px";
    }
  }

  /**
   * Affiche/masque l'élément "Plus" en fonction du nombre d'items présents dans cet élément
   */
  showOrHideMoreItems() {
    if (this.moreItemsList.childElementCount === 0) {
      this.moreItems.classList.add(PAGE_MENU_CLASSES.MORE_ITEMS_HIDDEN);
    } else if (this.moreItemsList.childElementCount > 0) {
      this.moreItems.classList.remove(PAGE_MENU_CLASSES.MORE_ITEMS_HIDDEN);
    }
  }

  /**
   * Affiche/masque la liste des items présents dans "Plus"
   * @param {boolean} show - Affiche/Masque la liste des items présents dans l'élément "Plus"
   */
  showOrHideMoreItemsList(show) {
    if (show) {
      this.moreItemsList.classList.remove(PAGE_MENU_CLASSES.MORE_ITEMS_LIST_HIDDEN);
    } else {
      this.moreItemsList.classList.add(PAGE_MENU_CLASSES.MORE_ITEMS_LIST_HIDDEN);
    }
  }

  /**
   * Création de listners permettant la mise en valeur d'un item lors d'un click sur ce dernier
   */
  setActiveItemManagement() {

    // On crée des listners pour mettre en évidence l'item sélectionné (l'élément "Plus" n'est pas concerné)
    for (let childIndex = 0; childIndex < this.pageMenu.childElementCount - 1; childIndex++) {
      let itemNavLink = this.pageMenu.children[ childIndex ].querySelector(PAGE_MENU_SELECTORS.NAV_LINK);

      // Si on est sur un item autre que le "Plus"
      itemNavLink.addEventListener(PAGE_MENU_LISTNERS.CLICK, () => {

        // On commence par supprimer les éventuelles classes déjà positionnées sur les autres items
        for (let childIndex = 0; childIndex < (this.pageMenu.childElementCount - 1); childIndex++) {
          let itemNavLink = this.pageMenu.children[ childIndex ].querySelector(PAGE_MENU_SELECTORS.NAV_LINK);
          itemNavLink.classList.remove(PAGE_MENU_CLASSES.ITEM_ACTIVE);
        }
        this.moreItems.classList.remove(PAGE_MENU_CLASSES.ITEM_ACTIVE);

        // On supprime également les éventuelles classes déjà positionnées sur les autres items présent dans l'élément "Plus"
        let moreItemsListChildsNumber = this.moreItems.querySelector(PAGE_MENU_SELECTORS.MORE_ITEMS_LIST)
                                            .childElementCount;
        for (let moreItemsListChildsIndex = 0; moreItemsListChildsIndex < moreItemsListChildsNumber;
             moreItemsListChildsIndex++) {
          let itemNavLink = this.moreItems.querySelector(PAGE_MENU_SELECTORS.MORE_ITEMS_LIST)
                                     .children[ moreItemsListChildsIndex ].querySelector(PAGE_MENU_SELECTORS.NAV_LINK);
          itemNavLink.classList.remove(PAGE_MENU_CLASSES.ITEM_ACTIVE_COLOR);
        }

        // On met en évidence l'item sélectionné
        if (!itemNavLink.parentElement.parentElement.parentElement.classList.contains(PAGE_MENU_CLASSES.MORE_ITEMS)) {
          itemNavLink.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
        } else {
          itemNavLink.parentElement.querySelector(PAGE_MENU_SELECTORS.NAV_LINK).classList
                                   .add(PAGE_MENU_CLASSES.ITEM_ACTIVE_COLOR);
          itemNavLink.parentElement.parentElement.parentElement.classList.add(PAGE_MENU_CLASSES.ITEM_ACTIVE);
        }
      });
    }
  }
}

document.addEventListener("DOMContentLoaded", () => {
  let pageMenus = document.querySelectorAll(PAGE_MENU_SELECTORS.CONTAINER);
  [].forEach.call(pageMenus, (pageMenu) => {
    new PageMenu(pageMenu);
  });
});

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.PageMenu = PageMenu;

export default PageMenu;
