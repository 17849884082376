import Ob1Component from "./ob1-component";

// la liste des classes utiles dans le code du composant
const classes = {
  selected: "ob1-box-radio-selected"
};

// la liste des sélecteurs que l'on utilise dans le code du composant
const selector = {
  parent: ".ob1-box-radio",
  radio: "input[type=radio]"
};

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */
class BoxRadioList extends Ob1Component {

  /**
   * Initialisation du composant BoxRadioList
   *
   * @param {HTMLElement} container le noeud DOM sur lequel est instancié le composant
   * @param {object} parameters les paramètres de ce composant
   */
  init(container, parameters) {

    // on appelle la méthode d'initialisation d'Ob1Component (obligatoire)
    super.init(container, parameters);
    this.items = [];

    const radioBtn = this.container.querySelectorAll(selector.radio);
    [].forEach.call(radioBtn, (item) => {
      if (item.checked) {
        this._addStyle(item);
      } else {
        this._removeStyle(item);
      }
      this.items.push(item);
    });

    this._addEvents();
  }

  /**
   * Gestion des événements sur le composant
   * @private
   */
  _addEvents () {
    this._onChange = (event) => {
      this.items.forEach((item) => {
        if (item === event.target) {
          this._addStyle(item);
        } else {
          this._removeStyle(item);
        }
      });
    };

    // un changement a eu lieu sur l'une des cases à cocher
    this.container.addEventListener("change", this._onChange);
  }

  /**
   * Rajouter les styles pour indiquer l'item parent sélectionné du radioButton
   * @param {HTMLElement} item le radiobutton
   * @private
   */
  _addStyle (item) {
    const parent = item.closest(selector.parent);
    if (parent) {
      parent.classList.add(classes.selected);
    }
  }

  /**
   * Supprimer les styles pour indiquer l'item parent sélectionné du radioButton
   * @param {HTMLElement} item le radiobutton
   * @private
   */
  _removeStyle (item) {
    const parent = item.closest(selector.parent);
    if (parent) {
      parent.classList.remove(classes.selected);
    }
  }

  /**
   * Décharge le composant
   */
  dispose () {

    // suppression de tous les event listeners qui ont été créés
    this.container.removeEventListener("change", this._onChange);

    // on appelle la méthode de suppression de composant d'Ob1Component (obligatoire)
    super.dispose();
  }
}

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.BoxRadioList = BoxRadioList;

export default BoxRadioList;
