/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */
class BandeauSliderSosh {
  constructor() {
    console.log("build success");
  }
}

export default BandeauSliderSosh;
