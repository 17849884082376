import Ob1Component from "./ob1-component";

// la liste des classes utiles dans le code du composant
const classes = {
  empty: "form-control-empty"
};

class FormControlEmpty extends Ob1Component {

  /**
   * Initialisation du composant FormcontrolEmpty
   *
   * @param {HTMLElement} container le noeud DOM sur lequel est instancié le composant
   * @param {object} parameters les paramètres de ce composant
   */
  init(container, parameters) {

    // on appelle la méthode d'initialisation d'Ob1Component (obligatoire)
    super.init(container, parameters);

    this._addEvents();
  }

  /**
   * Décharge le composant
   */
  dispose () {

    // suppression de tous les event listeners qui ont été créés
    this.container.removeEventListener("blur", this._hOnBlur);

    // on appelle la méthode de suppression de composant d'Ob1Component (obligatoire)
    super.dispose();
  }

  /**
   * Gestion des événements sur le composant
   * @private
   */
  _addEvents () {

    // création de tous les handlers d'événements
    // @see dispose() : ces handlers sont utilisés pour la déconnexion des événements
    this._hOnBlur = () => {
      if (this.container.value !== "" &&
        this.container.classList.contains(classes.empty)) {

        // l'élément a maintenant une valeur non nulle,
        // on peut supprimer la classe gérant la bonne disposition des éléments
        // le label est donc juste au-dessus de la liste déroulante et donc de la valeur sélectionnée
        this.container.classList.remove(classes.empty);
      } else if (this.container.value === "" &&
        !this.container.classList.contains(classes.empty)
      ) {

        // la liste déroulante n'a pas de valeur sélectionnée
        // on rajoute la classe pour que le label s'affiche au
        this.container.classList.add(classes.empty);
      }
    };

    // un changement a eu lieu sur l'une des cases à cocher
    this.container.addEventListener("blur", this._hOnBlur);
  }
}

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.FormControlEmpty = FormControlEmpty;

export default FormControlEmpty;
