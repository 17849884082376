class Accordion {
  toogleBodyDisplay(elementIndex) {
    let element = this.accordionsCardsLinks[ elementIndex ];
    let isExpanded = element.getAttribute("aria-expanded");
    if (isExpanded === "true") {
      element.setAttribute("aria-expanded", "false");
      document.getElementById(element.getAttribute("data-target")).classList.remove("show");
      element.classList.remove("show");
    } else {
      element.setAttribute("aria-expanded", "true");
      document.getElementById(element.getAttribute("data-target")).classList.add("show");
      element.classList.add("show");
    }
  }

  init(container) {
    this.container = container;
    this.accordionsCardsLinks = container.querySelectorAll(".card-header a");
    for (let i = 0; i < this.accordionsCardsLinks.length; i++) {
      let linkToSet = this.accordionsCardsLinks[ i ];

      // On met un listner pour pouvoir ouvrir ou fermer un bloc de l'accordéon
      linkToSet.addEventListener("click", ( ) => {
          this.toogleBodyDisplay(i);
        });
    }
  }

  constructor(container) {
    if (container) {
      if (typeof container.dataset.ref === "undefined") {
        this.ref = Math.random();
        Accordion.refs[ this.ref ] = this;
        container.dataset.ref = this.ref;
        this.init(container);
      } else {

        // If this element has already been instantiated, use the existing reference.
        return Accordion.refs[ container.dataset.ref ];
      }
    }
  }
}

Accordion.refs = {};

document.addEventListener("DOMContentLoaded", () => {
  let accordions = document.querySelectorAll(".accordion");
  [].forEach.call(accordions, (accordion) => {
    new Accordion(accordion);
  });
});

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.Accordion = Accordion;

export default Accordion;
