import Ob1Component from "./ob1-component";

// la liste des classes utiles dans le code du composant
const classes = {
  selected: "ob1-filterchip-selected"
};

// les textes qui peuvent être utilisés dans le composant
const text = {
  more_title: "Plus de filtres",
  more_title_small: "Filtres",
  btn_result_default: "Afficher les résultats",
  btn_result_none: "Pas de résultats",
  btn_result_one: "Afficher le résultat",
  btn_result: "Afficher les (x) résultats"
};

// la liste des sélecteurs que l'on utilise dans le code du composant
const selector = {
  item: ".ob1-filterchip .ob1-filterchip-input",
  item_selected: ".ob1-filterchip .ob1-filterchip-input:checked",
  item_all: ".ob1-filterchip:first-child .ob1-filter-chips-bar-btn",
  item_selected_hidden: ".ob1-filterchip.sr-only .ob1-filterchip-input:checked"
};

class FilterChipsBar extends Ob1Component {

  /**
   * Les paramètres par défaut du composant
   * Ils sont surchargés (par ordre de priorité) par :
   * - l'attribut data sur le noeud DOM du composant
   * - les paramètres utilisés au moment de l'instanciation
   * @returns {{unique: boolean}}
   */
  static getDefaultParams () {
    return {
      unique: false
    };
  }

  /**
   * Initialisation du composant filter chips
   *
   * @param {HTMLElement} container le noeud DOM sur lequel est instancié le composant
   * @param {object} parameters les paramètres de ce composant
   */
  init(container, parameters) {

    // on appelle la méthode d'initialisation d'Ob1Component (obligatoire)
    super.init(container, parameters);

    /**
     * Les éléments de filtre "simple" (tous sauf "tous / toutes")
     * @var {Array}
     */
    this.items = [];

    /**
     * Le filtre "spécial" "tous / toutes"
     * @var {HTMLElement}
     */
    this.itemAll = null;

    /**
     * Le filtre "spécial" "plus de filtres"
     * @var {HTMLElement}
     */
    this.itemMore = null;

    /**
     * La référence vers la modal qui peut être générée
     * @var {HTMLElement}
     */
    this.modal = null;

    this.hTimeOut = null;

    /**
     * Toutes les méthodes ou fonctions qui sont sensées être appelées pour donner la liste des filter chips sélectionnées
     * @type {Array}
     */
    this.onUpdateValuesMethods = [];

    this.setCountResultFct(() => {
      return false;
    });

    // traitement des filter chips "classiques"
    const filterChipsBarItem = this.container.querySelectorAll(selector.item);
    [].forEach.call(filterChipsBarItem, (item) => {
      this.items.push(item);
    });

    // traitement du filter chips spécial "tous / toutes"
    this.itemAll = this.container.querySelector(selector.item_all);

    // on crée le filter chip "plus de filtre"
    // => d'abord le container (on se base sur le type de tag des autres noeuds
    let itemMoreContainer = document.createElement(this.items[ 0 ].parentNode.tagName);
    itemMoreContainer.classList.add("ob1-filterchip", "d-none");

    // => puis le bouton en lui même
    this.itemMore = document.createElement("button");

    // ajout des classes spécifiques pour le filtre "plus de filtres"
    this.itemMore.classList.add("ob1-filterchip-label", "ob1-filter-chips-bar-btn");
    this.itemMore.setAttribute("type", "button");
    this.itemMore.setAttribute("title", "affiche l'intégralité des filtres disponibles");
    this.itemMore.innerHTML = `${text.more_title}`;
    itemMoreContainer.appendChild(this.itemMore);
    this.container.appendChild(itemMoreContainer);

    this._addEvents();
    this.resize();

    // a la fin de l'initialisation, on envoie le status du container pour indiquer les filtres activés
    this.triggerValuesEvent();
  }

  /**
   * Décharge le composant
   */
  dispose () {

    // suppression de tous les event listeners qui ont été créés
    this.container.removeEventListener("change", this._onChange);
    this.container.removeEventListener("click", this._onClicked);
    window.removeEventListener("resize", this._onResize);

    // on remet l'affichage d'origine des différentes chips
    this.itemMore.parentNode.removeChild(this.itemMore);
    this.items.map((item) => {
      item.parentNode.classList.remove("sr-only");
      item.setAttribute("tabindex", 0); // pour l'accessibilité
    });

    // on appelle la méthode de suppression de composant d'Ob1Component (obligatoire)
    super.dispose();
  }

  /**
   * Redimensionnement du filter chip bar en fonction de la place disponible
   */
  resize() {
    this.itemMore.parentNode.classList.remove("d-none");
    this.itemMore.parentNode.classList.add("d-block");
    if (this.isMobile()) {

      // en mobile, tous les filtres sont masqués
      // seul le filter chip "Plus de filtres" est visible
      this.items.map((item) => {
        item.parentNode.classList.add("sr-only");
        item.setAttribute("tabindex", -1); // pour l'accessibilité
      });
    } else {
      let isHidden = false;

      // en desktop, on affiche les filtres sur une seule ligne, ceux qui sont à la ligne sont masqués
      // ci dessous, code bof bof: je mets un texte plus long (pire cas) car le texte de "Plus de filtres"
      //   est complété avec le nombre d'éléments masqués
      //   ce qui le rendra plus long et peut donc avoir un impact sur la position des items
      this.itemMore.innerHTML = `${text.more_title} (xx)`;

      // on réaffiche tous les éléments du composant
      this.items.map((item) => {
        item.parentNode.classList.remove("sr-only");
        item.setAttribute("tabindex", 0); // pour l'accessibilité
      });

      // calcul de la largeur maximale du container
      let containerOffsetTop = this.container.offsetTop;
      let itemMoreOffsetTop = this.itemMore.parentNode.offsetTop;
      let index = this.items.length - 1;

      // tant que "plus de filtres" est à la ligne, on masque des items (en partant de la fin)
      while (index >= 0 && // il y a encore au moins un élément qui peut être masqué
      itemMoreOffsetTop > containerOffsetTop // le filter chip "plus de filtres" n'est pas à la même hauteur que le container
        ) {
        let item = this.items[ index-- ];
        item.parentNode.classList.add("sr-only");
        item.setAttribute("tabindex", -1); // pour l'accessibilité
        itemMoreOffsetTop = this.itemMore.parentNode.offsetTop; // la position du filter chip "plus de filtres" peut avoir évolué
        isHidden = true;
      }

      if (!isHidden) {

        // aucun élément n'est masqué
        // => on n'affiche plus le filter chip "plus de filtres"
        this.itemMore.parentNode.classList.remove("d-block");
        this.itemMore.parentNode.classList.add("d-none");
      }
    }

    this._updateSpecialChipDisplay();
  }

  /**
   * Met à jour l'affichage des chips spéciales :
   * - filter chip "plus de filtres" pour lister les éléments non affichés
   * - filter chip "tous / toutes" pour sélectionner tous / toutes
   * @private
   */
  _updateSpecialChipDisplay () {

    // filter chip "plus de filtres"
    let chipHiddenSelected = this.container.querySelectorAll(selector.item_selected_hidden);
    let countHiddenSelected = chipHiddenSelected.length;
    let textMore = this.isMobile() ? text.more_title_small : text.more_title;
    if (countHiddenSelected > 0) {
      this.itemMore.innerHTML = `${textMore} (${countHiddenSelected})`;
      this.itemMore.parentNode.classList.add(classes.selected);
    } else {
      this.itemMore.innerHTML = `${textMore}`;
      this.itemMore.parentNode.classList.remove(classes.selected);
    }

    // filter chip "tous / toutes"
    const chipsChecked = this.items.filter(chip => chip.checked === true);
    if (this.itemAll) {
      if (chipsChecked.length === 0) {
        this.itemAll.parentNode.classList.add(classes.selected);
      } else if (chipsChecked.length < this.items.length) {
        this.itemAll.parentNode.classList.remove(classes.selected);
      }
    }
  }

  /**
   * met à jour le contenu du bouton de la modal pour afficher le nombre de résultat
   * @private
   */
  _updateBtnDisplay () {
    let selectedValues = [];
    let modalFilterChips = this.modal.querySelectorAll(selector.item_selected);
    if (modalFilterChips.length === 0) { // pas d'éléments sélectionnés => donc ils sont tous sélectionnés
      modalFilterChips = this.modal.querySelectorAll(selector.item);
    }
    [].forEach.call(modalFilterChips, (item) => {
      selectedValues.push(item.value);
    });

    // récupération du nombre de résultats potentiels (fourni par le service)
    let count = this.displayCountFct(selectedValues);
    let btnNode = this.modal.querySelector(".modal-footer .btn");

    // mise à jour du texte du bouton en fonction du nombre de résultats potentiels
    let btnTxt = "";
    if (btnNode) {
      switch (count) {
        case false:
          btnTxt = text.btn_result_default;
          break;
        case 0:
          btnTxt = text.btn_result_none;
          break;
        case 1:
          btnTxt = text.btn_result_one;
          break;
        default:
          btnTxt = text.btn_result.replace("(x)", count);
          break;
      }
    }
    btnNode.innerText = btnTxt;
  }

  /**
   * Envoie les valeurs des filter chips sélectionnées pour le composant
   * @return {FilterChipsBar}
   */
  triggerValuesEvent () {
    if (this.onUpdateValuesMethods.length > 0) {

      // on a au moins une méthode à appeler, donc on récupère le statut
      const results = this._getSelectedValues();
      this.onUpdateValuesMethods.forEach(( onMethod ) => {
        onMethod(results);
      });
    }
    return this;
  }

  /**
   * Retourne un tableau contenant la liste des valeurs des filter chips sélectionnés
   * @return {string[]}
   * @private
   */
  _getSelectedValues () {
    let filterChipsSelected = this.items;
    filterChipsSelected = filterChipsSelected.filter(item => item.checked === true);
    return filterChipsSelected.map(item => item.value);
  }

  /**
   * Gestion des événements sur le composant
   * @private
   */
  _addEvents () {

    // création de tous les handlers d'événements
    // @see dispose() : ces handlers sont utilisés pour la déconnexion des événements
    this._onChange = () => {

      // pour éviter le déclenchement multiple quand on fait le changement sur plusieurs checkbox en même temps
      // ex: depuis la modal
      // => on simule un debounce
      clearTimeout(this.hTimeOut);
      this.hTimeOut = setTimeout(() => {
        this._updateSpecialChipDisplay();
        this.triggerValuesEvent();
      }, 10);
    };

    this._onClicked = (event) => {
      if (event.target === this.itemAll) {

        // on a cliqué sur le bouton "Tous / toutes"
        event.preventDefault();
        if (!this.itemAll.parentNode.classList.contains(classes.selected)) {
          this.items.map(item => item.checked = false);
          this._updateSpecialChipDisplay();
          this.triggerValuesEvent();
        }
      } else if (event.target === this.itemMore) {

        // on a cliqué sur le bouton "Plus de filtres"
        event.preventDefault();
        this._displayModal();
      }
    };

    this._onResize = () => {
      clearTimeout(this.resizetimeout);
      this.resizetimeout = setTimeout(() => {
        this.resize();
      }, 50);
    };

    // un changement a eu lieu sur l'une des cases à cocher
    this.container.addEventListener("change", this._onChange);

    // on a cliqué sur le container
    this.container.addEventListener("click", this._onClicked);

    // on recalcule les éléments à afficher dans le fil d'arianne au redimensionnement de la fenêtre
    window.addEventListener("resize", this._onResize);
  }

  /**
   * Affiche la modal pour afficher la liste de tous les filter chips disponibles
   * @private
   */
  _displayModal () {
    if (!this.modal) {

      // la modal n'a jamais été affichée, on génère la carcasse dynamiquement
      this.modal = document.createElement("div");
      this.modal.innerHTML = `<div class="modal fade" tabindex="-1" role="dialog" aria-hidden="true">
            <div class="modal-dialog" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3 class="h4 mb-1 mb-md-2"></h3>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Fermer">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body flex-row flex-wrap flex-grow-0">
                        <ul class="ob1-filter-chips-bar"></ul>
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary"></button>
                    </div>
                </div>
            </div>
        </div>`;
      document.body.appendChild(this.modal);
      $(this.modal.firstElementChild)
        .on("show.bs.modal", () => {

          // la modal s'affiche, on met à jour le contenu pour prendre le statut courant des filter chips de la page
          let modalChipsList = this.modal.querySelector(".modal-body .ob1-filter-chips-bar");
          this.items.forEach((filterChipElement) => {
            let filterChipElementClone = filterChipElement.parentNode.cloneNode(true);
            filterChipElementClone.classList.remove("sr-only");
            filterChipElementClone.firstElementChild.setAttribute("tabindex", "0");

            // les inputs / labels ont été cloné, mais il faut penser à modifier les identifiants des checkbox
            filterChipElementClone.firstElementChild.id += "tmp";
            filterChipElementClone.lastElementChild.setAttribute("for",
              filterChipElementClone.lastElementChild.getAttribute("for") + "tmp");
            modalChipsList.appendChild(filterChipElementClone);
          });

          // on met à jour le texte du bouton
          this._updateBtnDisplay();

          // on met à jour le titre de la modal
          let modalTitle = this.modal.querySelector(".modal-header .h4");
          if (this.isMobile()) {
            modalTitle.innerText = text.more_title_small;
          } else {
            modalTitle.innerText = text.more_title;
          }
          const ariaLabel = this.container.getAttribute("aria-label");
          if (ariaLabel) {
            modalTitle.setAttribute("aria-label", ariaLabel);
          }
        })
        .on("hidden.bs.modal", () => {

          // quand la modal est fermée, on vide le contenu, donc les anciennes filter chips...
          // c'est plus simple que de les mettre à jour
          const modalChipsList = this.modal.querySelector(".modal-body .ob1-filter-chips-bar");
          modalChipsList.innerHTML = "";
          this._focus();
        })
        .on("change", ".modal-body", (event) => {
          event.preventDefault();

          // on met à jour le texte du bouton
          this._updateBtnDisplay();
        })
        .on("click", ".modal-footer", () => {

          // clic sur le bouton de validation
          // on répercute les modifications de filtre de la modal, pour les mettre dans la page principale
          let filterChipsBarItem = this.modal.firstElementChild.querySelectorAll(selector.item);
          [].forEach.call(filterChipsBarItem, (item, index) => {
            if (this.items[ index ].checked !== item.checked) {

              // la simulation du clic va propager l'événement jusqu'au container ou il sera traité
              this.items[ index ].click();
            }
          });

          // fermeture de la modal et on remet le focus sur la page principale au niveau du filter chips bar
          $(this.modal.firstElementChild).modal("hide");
          this._focus();
        });
    }
    $(this.modal.firstElementChild)
      .modal();
  }

  /**
   * Met le focus sur le meilleur élément du composant
   * @private
   */
  _focus () {
    const selectedItems = this.container.querySelectorAll(selector.item_selected);
    if (selectedItems.length > 0) {

      // on met le focus sur le dernier filtre sélectionné
      selectedItems[ selectedItems.length - 1 ].focus();
    } else {

      // si aucun élément n'est sélectionné, on met le focus sur le bouton tous / toutes
      this.itemAll.focus();
    }
  }

  /**
   * Connecte une fonction pour qu'elle recoive la liste des valeurs des filter chips sélectionnés
   *
   * exemple :
   * // @type {Array} filterChipStatus
   * var updateStatus = function(filterChipStatus) {
   *   console.debug(filterChipStatus)
   * }
   *
   * // instanciation du composant :
   * var node = document.getElementById('myFilterChipsBar');
   * var filterChipBar = new FilterChipsBar(node);
   *
   * // On peut utiliser le composant en lui-même pour définir la fonction d'appel :
   * filterChipBar.onUpdateValues(updateStatus)
   *
   * // On peut aussi passer par l'intermédiaire du noeud DOM :
   * node.filterChipsBar('onUpdateValues', updateStatus)
   *
   * @param {Function} fct La fonction à appeler quand la liste des filter chips a changé
   * @return {FilterChipsBar}
   */
  onUpdateValues(fct) {
    this.onUpdateValuesMethods.push(fct);
    return this;
  }

  /**
   * Permet de définir la fonction qui sera appelée pour indiquer le nombre de résultat qui seront affichés
   * en fonction des filtres actuellement sélectionnés.
   *
   * @param {Function} fct La fonction à appeler pour connaître le nombre de résultat. Cette fonction :
   * - prendra en entrée un tableau de valeurs (string[])
   * - retournera soit :
   *  - un entier : le nombre de résultats
   *  - false : pour afficher le texte par défaut
   * @return {FilterChipsBar}
   */
  setCountResultFct (fct) {
    this.displayCountFct = fct;
    return this;
  }
}

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.FilterChipsBar = FilterChipsBar;

export default FilterChipsBar;
