const Selector = {
  PARENT: "select.form-control"
};

const CLASSNAMES = {
  empty: "form-control-empty"
};

class DropdownSelect {

  /**
   * Initialisation du fonctionnement JS pour le dropdown select
   *
   * @param container
   */
  init(container) {
    this.container = container;

    this.container.addEventListener("blur", () => {
      if (this.container.value !== "" &&
        this.container.classList.contains(CLASSNAMES.empty)) {

        // la liste déroulante a maintenant une valeur sélectionnée,
        // on peut supprimer la classe gérant la bonne disposition des éléments
        // le label est donc juste au-dessus de la liste déroulante et donc de la valeur sélectionnée
        this.container.classList.remove(CLASSNAMES.empty);
      } else if (this.container.value === "" &&
        !this.container.classList.contains(CLASSNAMES.empty)
      ) {

        // la liste déroulante n'a pas de valeur sélectionnée
        // on rajoute la classe pour que le label s'affiche au
        this.container.classList.add(CLASSNAMES.empty);
      }
    });
  }

  /**
   * Construteur
   *
   * The constructor should only contain the boiler plate code for finding or creating the reference.
   *
   * @param container
   * @return {*}
   */
  constructor(container) {
    if (container) {
      if (typeof container.dataset.ref === "undefined") {
        this.ref = Math.random();
        DropdownSelect.refs[ this.ref ] = this;
        container.dataset.ref = this.ref;
        this.init(container);
      } else {

        // If this element has already been instantiated, use the existing reference.
        return DropdownSelect.refs[ container.dataset.ref ];
      }
    }
  }
}

DropdownSelect.refs = {};

document.addEventListener("DOMContentLoaded", () => {
  let dropdowns = document.querySelectorAll(Selector.PARENT);
  [].forEach.call(dropdowns, (dropdown) => {
    new DropdownSelect(dropdown);
  });
});

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.DropdownSelect = DropdownSelect;

export default DropdownSelect;
