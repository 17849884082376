import Ob1Component from "./ob1-component";

const classes = {
  priceAmount: "price-amount",
  priceAmountInverse: "price-amount-inverse",
  pricePromoDurationByMonth: "price-promo-duration-by-month",
  priceAmountCustom: "price-amount-custom",
  pricePromoDurationByMonthCustom: "price-promo-duration-by-month-custom"
};

const texts = {
  euros: "€"
};

const separators = {
  dot: ".",
  comma: ",",
  zeroCentsByUnit: "0€",
  zeroCentsByDecade: "00€"
};

const pricesToConvert = [ classes.priceAmount,
  classes.priceAmountInverse,
  classes.pricePromoDurationByMonth,
  classes.priceAmountCustom,
  classes.pricePromoDurationByMonthCustom ];

class Price extends Ob1Component {

  /**
   * Initialisation du composant price
   *
   * @param {HTMLElement} container le noeud DOM sur lequel est instancié le composant
   * @param {object} parameters les paramètres de ce composant
   */
  init(container, parameters) {
    super.init(container, parameters);

    // Si le prix a un point comme séparateur entre la partie entière et les centimes, on remplace les points par des virgules
    this.replacePointByComma();

    // On supprime les centimes si ils sont à 0
    this.removeCentsIfRoundFigure();
  }

  /**
   * On ne doit avoir que des prix avec une virgule comme séparateur entre la partie entière et les centimes
   */
  replacePointByComma() {
    pricesToConvert.forEach((priceToConvert) => {
      let pricesAmounts = this.container.getElementsByClassName(priceToConvert);
      Array.from(pricesAmounts).forEach((priceAmounts) => {
        priceAmounts.innerText = priceAmounts.innerText.replace(separators.dot, separators.comma);
      });
    });
  }

  /**
   * On ne doit avoir que des prix avec une virgule comme séparateur entre la partie entière et les centimes
   */
  removeCentsIfRoundFigure() {
    pricesToConvert.forEach((priceToConvert) => {
      let pricesAmounts = this.container.getElementsByClassName(priceToConvert);
      Array.from(pricesAmounts).forEach((priceAmounts) => {
        let priceValues = priceAmounts.innerText.split(separators.comma);

        // On enlève les centimes si elles sont à 0 ou 00
        if (priceValues[ 1 ].substring(0, 2) === separators.zeroCentsByUnit) {
          let values = priceAmounts.innerText.split(separators.comma + separators.zeroCentsByUnit);
          priceAmounts.innerText = values[ 0 ] + texts.euros + values[ 1 ];
        }
        if (priceValues[ 1 ].substring(0, 3) === separators.zeroCentsByDecade) {
          let values = priceAmounts.innerText.split(separators.comma + separators.zeroCentsByDecade);
          priceAmounts.innerText = values[ 0 ] + texts.euros + values[ 1 ];
        }
      });
    });
  }
}

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.Price = Price;

export default Price;
