/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */

import * as OB1Common from "./ob1-common";

class DatePicker {

  /**
   * Initialisation du Date Picker
   */
  init(container, dates, mobileModeDisabled) {
    this.container = container;

    // Au chargement, test d'affichage de la version du Date Picker, mobile ou desktop
    this.changeDisplayMode(dates, mobileModeDisabled);

    // gestion de l'évenement resize du navigateur pour choix affichage du Date Picker mobile ou desktop
    window.addEventListener("resize", () => {
      this.changeDisplayMode(dates, mobileModeDisabled);
    });

    // Gestion des écouteurs
    this.container.addEventListener("click", (event) => {
      if (event.target.classList.contains("changeDatePickerSize")) {
        this.changeDatePickerSize(mobileModeDisabled);
      }
    });
  }


  // affichage ou masquage du datepicker Desktop en fonction de la résolution
  changeDisplayMode(dates, mobileModeDisabled) {
    let selectedDate = this.container.querySelector( ".inputDatePicker").value;
    let windowWidth = document.documentElement.clientWidth;

    if (windowWidth >= OB1Common.breakpoints.MEDIUM || mobileModeDisabled) {

      // Changement du type de date picker en desktop
      this.container.querySelector( ".inputDatePicker").type = "text";
      this.container.querySelector( ".labelDatePicker").classList.add("icon-calendar-day");


      // Paramétrage du datepicker Desktop
      $("#datepicker").datepicker({
        closeText: "Fermer",
        prevText: "Précédent",
        nextText: "Suivant",
        currentText: "Aujourd'hui",
        monthNames: [ "Janvier", "Février", "Mars", "Avril", "Mai", "Juin",
          "Juillet", "Août", "Septembre", "Octobre", "Novembre", "Décembre" ],
        monthNamesShort: [ "janv.", "févr.", "mars", "avr.", "mai", "juin",
          "juil.", "août", "sept.", "oct.", "nov.", "déc." ],
        dayNames: [ "dimanche", "lundi", "mardi", "mercredi", "jeudi", "vendredi", "samedi" ],
        dayNamesShort: [ "dim.", "lun.", "mar.", "mer.", "jeu.", "ven.", "sam." ],
        dayNamesMin: [ "Di", "Lu", "Ma", "Me", "Je", "Ve", "Sa" ],
        weekHeader: "Sem.",
        dateFormat: "dd/mm/yy",
        onChangeMonthYear: function () {

          // fonction pour modifier la largeur du Date Picker Desktop à X mois
          let datepickerDiv = document.getElementById("ui-datepicker-div");
          datepickerDiv.style.setProperty("font-size", "1.2em");
        },
        onSelect: function () {

          // fonction pour mettre à jour l'affichage du champs une fois la date sélectionnée
          document.getElementById("datepicker").classList.remove("form-control-empty");
        },
        beforeShowDay: function(date) {
          const dateWhitoutHours = jQuery.datepicker.formatDate("yy-mm-dd", date);
          if (dates.length > 0) {
            return [ dates.indexOf(dateWhitoutHours) !== -1 ];
          } else {
            return dateWhitoutHours;
          }
        },
        firstDay: 1,
        isRTL: false,
        showMonthAfterYear: false,
        yearSuffix: "",
        numberOfMonths: windowWidth < OB1Common.breakpoints.MEDIUM && mobileModeDisabled ? 1 : 2,
        minDate: new Date(),
        maxDate: "+1y",
        showButtonPanel: false
      });

      // si changement de résolution, on récupère date sélectionnée dans Date Picker Mobile pour alimenter dans Date Picker Desktop
      let smallSelectedDate = selectedDate.split("-").reverse().join("/");
      $("#datepicker").datepicker("setDate", smallSelectedDate);
    } else {

      // Changement du type de date picker en mobile
      this.container.querySelector( ".inputDatePicker").type = "date";
      this.container.querySelector( ".labelDatePicker").classList.remove("icon-calendar-month");

      // fonction pour mettre à jour la date du Date Picker Mobile à partir de la sélection du Date Picker Desktop
      let bigSelectedDate = selectedDate.split("/").reverse().join("-");
      this.container.querySelector( ".inputDatePicker").value = bigSelectedDate;
    }
  }

  // fonction pour modifier la largeur du Date Picker Desktop à X mois
  changeDatePickerSize(mobileModeDisabled) {
    let datepickerDiv = document.getElementById("ui-datepicker-div");
    datepickerDiv.style.setProperty("font-size", "1.2em");

    // Masquage de la DIV date Picker en mobile
    let windowWidth = document.documentElement.clientWidth;
    if (windowWidth < OB1Common.breakpoints.MEDIUM && !mobileModeDisabled) {
      document.getElementById("ui-datepicker-div").style.display = "none";
    }
  }

  /**
   * Constructeur du Date Picker
   */
  constructor(container, dates = [], mobileModeDisabled = false) {
    if (container) {
      if (typeof container.dataset.ref === "undefined") {
        this.ref = Math.random();
        DatePicker.refs[ this.ref ] = this;
        container.dataset.ref = this.ref;
        this.init(container, dates, mobileModeDisabled);
      } else {

        // If this element has already been instantiated, use the existing reference.
        return DatePicker.refs[ container.dataset.ref ];
      }
    }
  }

}

DatePicker.refs = {};

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.DatePicker = DatePicker;

export default DatePicker;
