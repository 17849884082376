import * as OB1Common from "./ob1-common";

/**
 * ------------------------------------------------------------------------
 * Class Definition
 * ------------------------------------------------------------------------
 */
class DateTimePicker {

  /**
   * Initialisation du Date Time Picker
   */
  init(container, proposals) {
    this.container = container;
    this.listOfValidDates = proposals;
    if (!this.listOfValidDates) {
      return;
    }

    // configuration des parametres pour pouvoir generer le code html des differents slides
    let datesOptions = { weekday: "long", year: "numeric", month: "short", day: "numeric" };
    let nbItemsPerSlide = 5;
    let nbDateTimeItems = proposals.datesTimes.length;
    let K = 0;
    let slideIndex = 0;
    let itemString = "";

    // en-tete du swiper
    itemString += `<div class="swiper-container">
      <div class="swiper-wrapper d-flex swiper-content">`;

    // on boucle X fois sur la liste des dates en fonction du nombre de dates souhaitees par slides
    while (K < nbDateTimeItems) {

      K = K + nbItemsPerSlide;

      // conteneur de chaque slide
      itemString += `<div class="swiper-slide pt-1 pt-md-0 swiper-item" id="slide${slideIndex}">
               <div class="row">
                   <div class="col-md-1"></div>`;

      slideIndex++;

      // pour chaque slide, on affiche les X dates correspondantes
      for (let i = K - nbItemsPerSlide; i < K; i++) {

        let entry = proposals.datesTimes[ i ];
        if (entry != null) {
          let entryDate = new Date(entry.dateProposals).toLocaleDateString("fr-FR", datesOptions);
          let nbTimeItems = Object.keys( entry.hourProposals ).length;

          itemString += `<div class="col-12 col-md-2 pt-2 pt-md-0 text-center">
                          <div class="row date-margin justify-content-center">`;

          // affichage de chaque jour
          if (nbTimeItems !== 0) {
            itemString += `<div class="col-12 pb-md-4 date-title text-center">${entryDate}</div>`;

            // affichage des boutons en fonction de la disponibilité pour sélection de l'heure
            for (let j in entry.hourProposals) {
                let hour = j;
                let status = entry.hourProposals[ j ];
                let statusClass = "hour-disable";
                if (status === "AVAILABLE") {
                  statusClass = "";
                } else if (status === "SELECTED") {
                  statusClass = "selected";
                }

                itemString += `<div class="col-4 col-md-12 px-0"><button  
                                  class="btn-hour selectDateHour ${statusClass}" 
                                  datadate="${entry.dateProposals}" datahour="${hour}">${hour}</button></div>`;
            }
          } else {

            // s'il n'y a aucun horaire pour la journée, affichage d'une info
            itemString += `<div class="col-12 date-title disabled text-center">${entryDate}</div>`;
            itemString += `<div class="col-12 d-md-none px-0">
                              <div class="alert-container alert-info px-2" role="alert">
                                <p class="alert">
                                  <span class="alert-icon icon-info" aria-hidden="true"></span>
                                  <span class="text-mw text-left">Aucun horaire disponible pour cette journée</span>
                                </p>
                              </div>
                            </div>`;
          }

          itemString += "</div></div>";

        }
      }

      itemString += `<div class="col-md-1"></div>
        </div>
        </div>`;
    }

    // affichage des boutons du swiper
    itemString += `</div>
         <div class="swiper-button-prev d-none d-md-block" title="Précédant"></div>
         <div class="swiper-button-next d-none d-md-block" title="Suivant"></div>
      </div>
      <!-- BOUTONS TEXTUELS SUPPLEMENTAIRES DE SWIPE : VERSION DESKTOP UNIQUEMENT -->
      <div class="row my-3 d-none d-md-flex">
         <div class="col-6 text-right"><button class="btn btn-secondary disabled swipeLeft" 
             id="prevDayButton">Jours précédents</button></div>
         <div class="col-6"><button class="btn btn-secondary swipeRight" 
             id="nextDayButton">Prochains jours</button></div>
      </div>`;

    // Injection du contenu HTML généré
    this.container.innerHTML = itemString;


    // Initialisation du swiper
    this.mySwiper = new Swiper(".swiper-container", {
      autoplay: false,

      // enable accessibility
      a11y: true,
      keyboard: {
        enabled: true,
        onlyInViewport: false
      },
      freeMode: false,

      // If we need pagination
      pagination: false,

      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev"
      },
      slidesPerView: 1,
      centeredSlides: true,

      on: {
        slideChange: () => {
          this.isSwiperAtBeginning();
          this.isSwiperAtEnd();
        }
      }
    });

    // Ajout de nouvelles fonctionnalités de swipe pour les différents boutons
    this.dateTimePickerSwiper = this.container.querySelector(".swiper-container").swiper;

    // gestion de l'évenement resize du navigateur
    window.addEventListener("resize", () => {
      this.changeDisplayMode();
    });

    // Appel de la méthode d'affichage du swiper ou non, au chargement
    this.changeDisplayMode();

    // Gestion des écouteurs
    this.container.addEventListener("click", (event) => {
      if (event.target.classList.contains("swipeLeft")) {
        this.swipeLeft();
      } else if (event.target.classList.contains("swipeRight")) {
        this.swipeRight();
      } else if (event.target.classList.contains("selectDateHour")) {
        let selectedDate = event.target.attributes.datadate.value;
        let selectedHour = event.target.attributes.datahour.value;
        this.setDateTimeForm(event.target, selectedDate, selectedHour);
      }
    });

  }


  swipeLeft() {
    this.dateTimePickerSwiper.slidePrev();
    this.isSwiperAtBeginning();
  }

  swipeRight() {
    this.dateTimePickerSwiper.slideNext();
    this.isSwiperAtEnd();
  }

  swipeToNextAvailableDate(slideIndex) {
    this.dateTimePickerSwiper.slideTo(slideIndex);
  }

  isSwiperAtBeginning() {
    var button = this.container.querySelector("#prevDayButton");
    if (this.dateTimePickerSwiper.isBeginning === true) {
      button.classList.add("disabled");
    } else {
      button.classList.remove("disabled");
    }
  }

  isSwiperAtEnd() {
    var button = this.container.querySelector("#nextDayButton");
    if (this.dateTimePickerSwiper.isEnd === true) {
      button.classList.add("disabled");
    } else {
      button.classList.remove("disabled");
    }
  }

  // affichage ou masquage du swiper en fonction de la résolution
  changeDisplayMode() {
    this.dateTimePickerSwiper = this.container.querySelector(".swiper-container").swiper;
    var swiperContent = this.container.querySelector(".swiper-content");
    var swiperItem = this.container.querySelector(".swiper-item");
    let w = document.documentElement.clientWidth;
    if (w < OB1Common.breakpoints.MEDIUM) {
      this.dateTimePickerSwiper.allowSlidePrev = false;
      this.dateTimePickerSwiper.allowSlideNext = false;
      swiperContent.classList.remove("swiper-wrapper", "d-flex");
      swiperItem.classList.remove("swiper-slide");
      swiperItem.style.width = "100%";
      this.dateTimePickerSwiper.slideTo(0);
    } else {
      this.dateTimePickerSwiper.allowSlidePrev = true;
      this.dateTimePickerSwiper.allowSlideNext = true;
      swiperContent.classList.add("swiper-wrapper", "d-flex");
      swiperItem.classList.add("swiper-slide");
      this.dateTimePickerSwiper.slideTo(0);
    }
  }


  // fonction pour passer aux champs cachés la valeur sélectionnée par l'utilisateur
  setDateTimeForm(elt, date, hour) {
    var selectedButton = this.container.querySelector("button.selected");
    if (selectedButton != null) {
      selectedButton.classList.remove("selected");
    }
    elt.classList.add("selected");

    let dateSelected = new Date(date).toISOString().slice(0, 10);
    let timeSelected = hour;
    if (document.getElementById("selectedDate")) {
      document.getElementById("selectedDate").value = dateSelected;
    }
    if (document.getElementById("selectedDate")) {
      document.getElementById("selectedTime").value = timeSelected;
    }
  }

  constructor(container, proposals) {
    if (container) {
      if (typeof container.dataset.ref === "undefined") {
        this.ref = Math.random();
        DateTimePicker.refs[ this.ref ] = this;
        container.dataset.ref = this.ref;
        this.init(container, proposals);
      } else {

        // If this element has already been instantiated, use the existing reference.
        return DateTimePicker.refs[ container.dataset.ref ];
      }
    }
  }
}

DateTimePicker.refs = {};

// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.DateTimePicker = DateTimePicker;

export default DateTimePicker;
