import Ob1Component from "./ob1-component";

const classes = {
  dBlock: "d-block"
};

const listenersTypes = {
  keyUp: "keyup",
  focus: "focus",
  blur: "blur"
};

const selectors = {
  textField: ".ob1-promotional-code-content .form-control",
  addPromotionalCode: ".ob1-add-promotional-code:not(.ob1-loading)"
};

class PromotionalCode extends Ob1Component {

  /**
   * Initialisation du composant
   *
   * @param {HTMLElement} container le noeud DOM sur lequel est instancié le composant
   * @param {object} parameters les paramètres d'instanciation de ce composant
   */
  init(container, parameters) {
    super.init(container, parameters);
    this.promotionalCodeTextField = this.container.querySelector(selectors.textField);

    // Gestion de l'affichage du bouton d'ajout
    if (this.promotionalCodeTextField !== null) {
      this.promotionalCodeTextField.addEventListener(listenersTypes.keyUp,
        () => this.buttonDisplayManager(true));
      this.promotionalCodeTextField.addEventListener(listenersTypes.focus,
        () => this.buttonDisplayManager(true));
      this.promotionalCodeTextField.addEventListener(listenersTypes.blur,
        () => this.buttonDisplayManager(false));
    }
  }

  /**
   * Décharge le composant
   */
  dispose () {

    // suppression de tous les event listeners qui ont été créés
    this.promotionalCodeTextField.removeEventListener(listenersTypes.keyUp, this.buttonDisplayManager(true));
    this.promotionalCodeTextField.removeEventListener(listenersTypes.focus, this.buttonDisplayManager(true));
    this.promotionalCodeTextField.removeEventListener(listenersTypes.blur, this.buttonDisplayManager(false));

    // on appelle la méthode de suppression de composant d'Ob1Component (obligatoire)
    super.dispose();
  }

  /**
   * Gestionnaire de l'affichage du bouton d'ajout du code promotionnel
   *
   * @param {boolean} focused précise si le focus est postionné sur le text field
   */
  buttonDisplayManager(focused) {
    const addPromotionnalCode = this.container.querySelector(selectors.addPromotionalCode);
    if (addPromotionnalCode !== null) {
      if (this.promotionalCodeTextField.value !== "" || focused) {
        addPromotionnalCode.classList.add(classes.dBlock);
      } else {
        addPromotionnalCode.classList.remove(classes.dBlock);
      }
    }
  }
}

window.PromotionalCode = PromotionalCode;

export default PromotionalCode;
