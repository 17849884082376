import Ob1Component from "./ob1-component";

// Initilisation des pays / icônes associés
const countriesRepository = [
  { country: "Afghanistan", icon: "flag-icon-af" },
  { country: "Afrique du Sud", icon: "flag-icon-za" },
  { country: "Åland, Îles", icon: "flag-icon-ax" },
  { country: "Albanie", icon: "flag-icon-al" },
  { country: "Algérie", icon: "flag-icon-dz" },
  { country: "Allemagne", icon: "flag-icon-de" },
  { country: "Andorre", icon: "flag-icon-ad" },
  { country: "Angola", icon: "flag-icon-ao" },
  { country: "Anguilla", icon: "flag-icon-ai" },
  { country: "Antarctique", icon: "flag-icon-aq" },
  { country: "Antigua et Barbuda", icon: "flag-icon-ag" },
  { country: "Arabie Saoudite", icon: "flag-icon-sa" },
  { country: "Argentine", icon: "flag-icon-ar" },
  { country: "Arménie", icon: "flag-icon-am" },
  { country: "Aruba", icon: "flag-icon-aw" },
  { country: "Australie", icon: "flag-icon-au" },
  { country: "Autriche", icon: "flag-icon-at" },
  { country: "Azerbaïdjan", icon: "flag-icon-az" },
  { country: "Bahamas", icon: "flag-icon-bs" },
  { country: "Bahrein", icon: "flag-icon-bh" },
  { country: "Bangladesh", icon: "flag-icon-bd" },
  { country: "Barbade", icon: "flag-icon-bb" },
  { country: "Bélarus", icon: "flag-icon-by" },
  { country: "Belgique", icon: "flag-icon-be" },
  { country: "Bélize", icon: "flag-icon-bz" },
  { country: "Bénin", icon: "flag-icon-bj" },
  { country: "Bermudes", icon: "flag-icon-bm" },
  { country: "Bhoutan", icon: "flag-icon-bt" },
  { country: "Bolivie (État plurinational de)", icon: "flag-icon-bo" },
  { country: "Bonaire, Saint-Eustache et Saba", icon: "flag-icon-bq" },
  { country: "Bosnie-Herzégovine", icon: "flag-icon-ba" },
  { country: "Botswana", icon: "flag-icon-bw" },
  { country: "Bouvet, Ile", icon: "flag-icon-bv" },
  { country: "Brésil", icon: "flag-icon-br" },
  { country: "Brunéi Darussalam", icon: "flag-icon-bn" },
  { country: "Bulgarie", icon: "flag-icon-bg" },
  { country: "Burkina Faso", icon: "flag-icon-bf" },
  { country: "Burundi", icon: "flag-icon-bi" },
  { country: "Cabo Verde", icon: "flag-icon-cv" },
  { country: "Caïmans, Iles", icon: "flag-icon-ky" },
  { country: "Cambodge", icon: "flag-icon-kh" },
  { country: "Cameroun", icon: "flag-icon-cm" },
  { country: "Canada", icon: "flag-icon-ca" },
  { country: "Chili", icon: "flag-icon-cl" },
  { country: "Chine", icon: "flag-icon-cn" },
  { country: "Christmas, île", icon: "flag-icon-cx" },
  { country: "Chypre", icon: "flag-icon-cy" },
  { country: "Cocos/Keeling (Îles)", icon: "flag-icon-cc" },
  { country: "Colombie", icon: "flag-icon-co" },
  { country: "Comores", icon: "flag-icon-km" },
  { country: "Congo", icon: "flag-icon-cg" },
  { country: "Congo, République démocratique du", icon: "flag-icon-cd" },
  { country: "Cook, Iles", icon: "flag-icon-ck" },
  { country: "Corée, République de", icon: "flag-icon-kr" },
  { country: "Corée, République populaire démocratique de", icon: "flag-icon-kp" },
  { country: "Costa Rica", icon: "flag-icon-cr" },
  { country: "Côte d'Ivoire", icon: "flag-icon-ci" },
  { country: "Croatie", icon: "flag-icon-hr" },
  { country: "Cuba", icon: "flag-icon-cu" },
  { country: "Curaçao", icon: "flag-icon-cw" },
  { country: "Danemark", icon: "flag-icon-dk" },
  { country: "Djibouti", icon: "flag-icon-dj" },
  { country: "Dominicaine, République", icon: "flag-icon-do" },
  { country: "Dominique", icon: "flag-icon-dm" },
  { country: "Egypte", icon: "flag-icon-eg" },
  { country: "El Salvador", icon: "flag-icon-sv" },
  { country: "Emirats arabes unis", icon: "flag-icon-ae" },
  { country: "Equateur", icon: "flag-icon-ec" },
  { country: "Erythrée", icon: "flag-icon-er" },
  { country: "Espagne", icon: "flag-icon-es" },
  { country: "Estonie", icon: "flag-icon-ee" },
  { country: "Etats-Unis d'Amérique", icon: "flag-icon-us" },
  { country: "Ethiopie", icon: "flag-icon-et" },
  { country: "Falkland/Malouines (Îles)", icon: "flag-icon-fk" },
  { country: "Féroé, îles", icon: "flag-icon-fo" },
  { country: "Fidji", icon: "flag-icon-fj" },
  { country: "Finlande", icon: "flag-icon-fi" },
  { country: "France", icon: "flag-icon-fr" },
  { country: "Gabon", icon: "flag-icon-ga" },
  { country: "Gambie", icon: "flag-icon-gm" },
  { country: "Géorgie", icon: "flag-icon-ge" },
  { country: "Géorgie du sud et les îles Sandwich du sud", icon: "flag-icon-gs" },
  { country: "Ghana", icon: "flag-icon-gh" },
  { country: "Gibraltar", icon: "flag-icon-gi" },
  { country: "Grèce", icon: "flag-icon-gr" },
  { country: "Grenade", icon: "flag-icon-gd" },
  { country: "Groenland", icon: "flag-icon-gl" },
  { country: "Guadeloupe", icon: "flag-icon-gp" },
  { country: "Guam", icon: "flag-icon-gu" },
  { country: "Guatemala", icon: "flag-icon-gt" },
  { country: "Guernesey", icon: "flag-icon-gg" },
  { country: "Guinée", icon: "flag-icon-gn" },
  { country: "Guinée-Bissau", icon: "flag-icon-gw" },
  { country: "Guinée équatoriale", icon: "flag-icon-gq" },
  { country: "Guyana", icon: "flag-icon-gy" },
  { country: "Guyane française", icon: "flag-icon-gf" },
  { country: "Haïti", icon: "flag-icon-ht" },
  { country: "Heard, Ile et MacDonald, îles", icon: "flag-icon-hm" },
  { country: "Honduras", icon: "flag-icon-hn" },
  { country: "Hong Kong", icon: "flag-icon-hk" },
  { country: "Hongrie", icon: "flag-icon-hu" },
  { country: "Île de Man", icon: "flag-icon-im" },
  { country: "Îles mineures éloignées des Etats-Unis", icon: "flag-icon-um" },
  { country: "Îles vierges britanniques", icon: "flag-icon-vg" },
  { country: "Îles vierges des Etats-Unis", icon: "flag-icon-vi" },
  { country: "Inde", icon: "flag-icon-in" },
  { country: "Indien (Territoire britannique de l'océan)", icon: "flag-icon-io" },
  { country: "Indonésie", icon: "flag-icon-id" },
  { country: "Iran, République islamique d'", icon: "flag-icon-ir" },
  { country: "Iraq", icon: "flag-icon-iq" },
  { country: "Irlande", icon: "flag-icon-ie" },
  { country: "Islande", icon: "flag-icon-is" },
  { country: "Israël", icon: "flag-icon-il" },
  { country: "Italie", icon: "flag-icon-it" },
  { country: "Jamaïque", icon: "flag-icon-jm" },
  { country: "Japon", icon: "flag-icon-jp" },
  { country: "Jersey", icon: "flag-icon-je" },
  { country: "Jordanie", icon: "flag-icon-jo" },
  { country: "Kazakhstan", icon: "flag-icon-kz" },
  { country: "Kenya", icon: "flag-icon-ke" },
  { country: "Kirghizistan", icon: "flag-icon-kg" },
  { country: "Kiribati", icon: "flag-icon-ki" },
  { country: "Koweït", icon: "flag-icon-kw" },
  { country: "Lao, République démocratique populaire", icon: "flag-icon-la" },
  { country: "Lesotho", icon: "flag-icon-ls" },
  { country: "Lettonie", icon: "flag-icon-lv" },
  { country: "Liban", icon: "flag-icon-lb" },
  { country: "Libéria", icon: "flag-icon-lr" },
  { country: "Libye", icon: "flag-icon-ly" },
  { country: "Liechtenstein", icon: "flag-icon-li" },
  { country: "Lituanie", icon: "flag-icon-lt" },
  { country: "Luxembourg", icon: "flag-icon-lu" },
  { country: "Macao", icon: "flag-icon-mo" },
  { country: "Macédoine, l'ex-République yougoslave de", icon: "flag-icon-mk" },
  { country: "Madagascar", icon: "flag-icon-mg" },
  { country: "Malaisie", icon: "flag-icon-my" },
  { country: "Malawi", icon: "flag-icon-mw" },
  { country: "Maldives", icon: "flag-icon-mv" },
  { country: "Mali", icon: "flag-icon-ml" },
  { country: "Malte", icon: "flag-icon-mt" },
  { country: "Mariannes du nord, Iles", icon: "flag-icon-mp" },
  { country: "Maroc", icon: "flag-icon-ma" },
  { country: "Marshall, Iles", icon: "flag-icon-mh" },
  { country: "Martinique", icon: "flag-icon-mq" },
  { country: "Maurice", icon: "flag-icon-mu" },
  { country: "Mauritanie", icon: "flag-icon-mr" },
  { country: "Mayotte", icon: "flag-icon-yt" },
  { country: "Mexique", icon: "flag-icon-mx" },
  { country: "Micronésie, Etats Fédérés de", icon: "flag-icon-fm" },
  { country: "Moldova, République de", icon: "flag-icon-md" },
  { country: "Monaco", icon: "flag-icon-mc" },
  { country: "Mongolie", icon: "flag-icon-mn" },
  { country: "Monténégro", icon: "flag-icon-me" },
  { country: "Montserrat", icon: "flag-icon-ms" },
  { country: "Mozambique", icon: "flag-icon-mz" },
  { country: "Myanmar", icon: "flag-icon-mm" },
  { country: "Namibie", icon: "flag-icon-na" },
  { country: "Nauru", icon: "flag-icon-nr" },
  { country: "Népal", icon: "flag-icon-np" },
  { country: "Nicaragua", icon: "flag-icon-ni" },
  { country: "Niger", icon: "flag-icon-ne" },
  { country: "Nigéria", icon: "flag-icon-ng" },
  { country: "Niue", icon: "flag-icon-nu" },
  { country: "Norfolk, Ile", icon: "flag-icon-nf" },
  { country: "Norvège", icon: "flag-icon-no" },
  { country: "Nouvelle-Calédonie", icon: "flag-icon-nc" },
  { country: "Nouvelle-Zélande", icon: "flag-icon-nz" },
  { country: "Oman", icon: "flag-icon-om" },
  { country: "Ouganda", icon: "flag-icon-ug" },
  { country: "Ouzbékistan", icon: "flag-icon-uz" },
  { country: "Pakistan", icon: "flag-icon-pk" },
  { country: "Palaos", icon: "flag-icon-pw" },
  { country: "Palestine, Etat de", icon: "flag-icon-ps" },
  { country: "Panama", icon: "flag-icon-pa" },
  { country: "Papouasie-Nouvelle-Guinée", icon: "flag-icon-pg" },
  { country: "Paraguay", icon: "flag-icon-py" },
  { country: "Pays-Bas", icon: "flag-icon-nl" },
  { country: "Pérou", icon: "flag-icon-pe" },
  { country: "Philippines", icon: "flag-icon-ph" },
  { country: "Pitcairn", icon: "flag-icon-pn" },
  { country: "Pologne", icon: "flag-icon-pl" },
  { country: "Polynésie française", icon: "flag-icon-pf" },
  { country: "Porto Rico", icon: "flag-icon-pr" },
  { country: "Portugal", icon: "flag-icon-pt" },
  { country: "Qatar", icon: "flag-icon-qa" },
  { country: "République arabe syrienne", icon: "flag-icon-sy" },
  { country: "République centrafricaine", icon: "flag-icon-cf" },
  { country: "Réunion", icon: "flag-icon-re" },
  { country: "Roumanie", icon: "flag-icon-ro" },
  { country: "Royaume-Uni de Grande-Bretagne et d'Irlande du Nord", icon: "flag-icon-gb" },
  { country: "Russie, Fédération de", icon: "flag-icon-ru" },
  { country: "Rwanda", icon: "flag-icon-rw" },
  { country: "Sahara occidental", icon: "flag-icon-eh" },
  { country: "Saint-Barthélemy", icon: "flag-icon-bl" },
  { country: "Saint-Kitts-et-Nevis", icon: "flag-icon-kn" },
  { country: "Saint-Marin", icon: "flag-icon-sm" },
  { country: "Saint-Martin (partie française)", icon: "flag-icon-mf" },
  { country: "Saint-Martin (partie néerlandaise)", icon: "flag-icon-sx" },
  { country: "Saint-Pierre-et-Miquelon", icon: "flag-icon-pm" },
  { country: "Saint-Siège", icon: "flag-icon-va" },
  { country: "Saint-Vincent-et-les-Grenadines", icon: "flag-icon-vc" },
  { country: "Sainte-Hélène, Ascension et Tristan da Cunha", icon: "flag-icon-sh" },
  { country: "Sainte-Lucie", icon: "flag-icon-lc" },
  { country: "Salomon, Iles", icon: "flag-icon-sb" },
  { country: "Samoa", icon: "flag-icon-ws" },
  { country: "Samoa américaines", icon: "flag-icon-as" },
  { country: "Sao Tomé-et-Principe", icon: "flag-icon-st" },
  { country: "Sénégal", icon: "flag-icon-sn" },
  { country: "Serbie", icon: "flag-icon-rs" },
  { country: "Seychelles", icon: "flag-icon-sc" },
  { country: "Sierra Leone", icon: "flag-icon-sl" },
  { country: "Singapour", icon: "flag-icon-sg" },
  { country: "Slovaquie", icon: "flag-icon-sk" },
  { country: "Slovénie", icon: "flag-icon-si" },
  { country: "Somalie", icon: "flag-icon-so" },
  { country: "Soudan", icon: "flag-icon-sd" },
  { country: "Soudan du Sud", icon: "flag-icon-ss" },
  { country: "Sri Lanka", icon: "flag-icon-lk" },
  { country: "Suède", icon: "flag-icon-se" },
  { country: "Suisse", icon: "flag-icon-ch" },
  { country: "Suriname", icon: "flag-icon-sr" },
  { country: "Svalbard et île Jan Mayen", icon: "flag-icon-sj" },
  { country: "Swaziland", icon: "flag-icon-sz" },
  { country: "Tadjikistan", icon: "flag-icon-tj" },
  { country: "Taïwan, Province de Chine", icon: "flag-icon-tw" },
  { country: "Tanzanie, République unie de", icon: "flag-icon-tz" },
  { country: "Tchad", icon: "flag-icon-td" },
  { country: "Tchèque, République", icon: "flag-icon-cz" },
  { country: "Terres australes françaises", icon: "flag-icon-tf" },
  { country: "Thaïlande", icon: "flag-icon-th" },
  { country: "Timor-Leste", icon: "flag-icon-tl" },
  { country: "Togo", icon: "flag-icon-tg" },
  { country: "Tokelau", icon: "flag-icon-tk" },
  { country: "Tonga", icon: "flag-icon-to" },
  { country: "Trinité-et-Tobago", icon: "flag-icon-tt" },
  { country: "Tunisie", icon: "flag-icon-tn" },
  { country: "Turkménistan", icon: "flag-icon-tm" },
  { country: "Turks-et-Caïcos (Îles)", icon: "flag-icon-tc" },
  { country: "Turquie", icon: "flag-icon-tr" },
  { country: "Tuvalu", icon: "flag-icon-tv" },
  { country: "Ukraine", icon: "flag-icon-ua" },
  { country: "Uruguay", icon: "flag-icon-uy" },
  { country: "Vanuatu", icon: "flag-icon-vu" },
  { country: "Venezuela (République bolivarienne du)", icon: "flag-icon-ve" },
  { country: "Viet Nam", icon: "flag-icon-vn" },
  { country: "Wallis et Futuna", icon: "flag-icon-wf" },
  { country: "Yémen", icon: "flag-icon-ye" },
  { country: "Zambie", icon: "flag-icon-zm" },
  { country: "Zimbabwe", icon: "flag-icon-zw" }
];

const accessibilityLabels = {
  nav: "Lorsque des résultats sont disponibles, utiliser les flèches haut et bas pour naviguer dans les propositions." +
    "Depuis un périphérique tactile, explorer en utilisant un balayage.",
  characters: "Merci de saisir un minimum de 3 caractères.",
  results: " résultats sont disponibles."
};

const searchFieldEventsListener = {
  KEYUP: "keyup",
  KEYDOWN: "keydown",
  CLICK: "click"
};

const searchFieldEventsCode = {
  BACKSPACE_CODE: "Backspace",
  DELETE_CODE: "Delete",
  SPACE: "Space",
  ENTER: "Enter",
  ESCAPE: "Escape",
  UP: "ArrowUp",
  DOWN: "ArrowDown",
  RIGHT: "ArrowRight",
  LEFT: "ArrowLeft",
  TAB: "Tab"
};

const searchFieldElements = {
  RESULT_LINE: "li",
  SPAN: "span"
};

const searchFieldClasses = {
  FIELD_SEARCH_INVALID: "is-invalid",
  FIELD_SEARCH_COUNTRY_SELECTED: "search-country-selected",
  RESULT_LINE: "result-line",
  BOLD: "font-weight-bold",
  FLAG_ICON: "flag-icon",
  DISPLAY_NONE: "d-none",
  DISPLAY_INLINE_BLOCK: "d-inline-block",
  MARGIN_RIGHT_1: "mr-1",
  FORM_CONTROL_HELP: "form-control-help"
};

const searchFieldSelectors = {
  FIELD_SEARCH_COUNTRY_SELECTED: "span.search-country-selected"
};

const searchFieldAttributes = {
  ROLE: "role",
  ROLE_BUTTON_VALUE: "button",
  ROLE_OPTION: "option",
  ARIA_POSINSET: "aria-posinset",
  ARIA_SETSIZE: "aria-setsize",
  ARIA_SELECTED: "aria-selected",
  TABINDEX: "tabindex",
  TABINDEX_ZERO_VALUE: "0",
  TABINDEX_NEGATIVE_VALUE: "-1"
};

const maxLinesNumber = 7;

class SearchField extends Ob1Component  {

  init(container, parameters) {

    this.container = container; // récupération des paramètres d'instanciation

    parameters = parameters || {};
    this.parameters = {};

    this.maxLinesNumber = 0;

    let repository = adressesRepository;
    let searchFieldId = this.container.dataset.searchfieldSearchfield;
    let searchFieldResultsId = this.container.dataset.searchfieldSearchfieldResults;
    let isCountrySearch = this.container.dataset.searchfieldIsCountrySearch;

    // on appelle la méthode d'initialisation d'Ob1Component (obligatoire)
    super.init(container, parameters);

    // Référentiel utilisé pour afficher les résultats
    this.repository = repository;

    // Nombre total de résultats
    this.repositoryLength = (repository.length) - 1;

    // Elément correspondant au champ de recherche
    this.searchField = document.getElementById(searchFieldId);

    // Elément correspondant au champ de recherche
    this.searchFieldResults = document.getElementById(searchFieldResultsId);

    // Est ce une recherche de pays
    this.isCountrySearch = isCountrySearch;

    // On met un listner pour pouvoir raffraichir les résultats à chaque fois que la personne relache une touche du clavier
    this.searchField.addEventListener(searchFieldEventsListener.KEYUP, () =>
      this.refreshSearchFieldResults());

    // On met un listner pour pouvoir supprimer le drapeau présent dans le searchField lorsqu'un résultat a déjà été sélectionné
    this.searchField.addEventListener(searchFieldEventsListener.KEYDOWN,
      (event) => this.searchCountryFieldReinitilisation(event));

    // On met un listner pour vérifier si l'utilisateur clique sur la croix du champs de recherche et effacer la liste des résultats
    this.searchField.addEventListener("search", () => {
      this.refreshSearchFieldResults();
      this.selectedResult = -1;
    });

    // ajout d'infos pour l'accessibilité
    this.searchField.setAttribute("aria-control", searchFieldId);
    this.searchField.setAttribute("role", "combobox");
    this.searchField.setAttribute("aria-expanded", "false");
    this.searchField.setAttribute("autocomplete", "off");
    this.searchField.setAttribute("aria-autocomplete", "list");

    this.accessibilitySr = this.searchField.parentNode.querySelector(".search-field-sr");
    this.accessibility = document.createElement("span");
    this.accessibilitySr.appendChild(this.accessibility);
    this.accessibility.innerHTML = accessibilityLabels.nav;
    this.accessibility.innerHTML += accessibilityLabels.characters;

    this.selectedResult = -1;
  }

  /**
   * Rafraîchit les résultats
   */
  refreshSearchFieldResults() {

    // On supprime les résultats affichés précédemment
    this.searchFieldResults.innerHTML = "";

    // On efface le drapeau
    if (this.isCountrySearch === "true") {
      const searchFieldFlagElement = document.querySelector(searchFieldSelectors.FIELD_SEARCH_COUNTRY_SELECTED);
      if (searchFieldFlagElement !== null) {
        searchFieldFlagElement.remove();
        this.searchField.classList.remove(searchFieldClasses.FIELD_SEARCH_COUNTRY_SELECTED);
      }
    }

    // Il faut au moins 1 caractère renseigné dans le champ de recherche pour afficher des résultats
    if (this.searchField.value.length > 0) {

      // On filtre les résultats à afficher dans le cas d'une recherche de pays
      if (this.isCountrySearch === "true") {
        this.repository = this.getFilteredListOfCountries();
      }

      // On affiche le message d'erreur dans le cas où aucun résultat n'est à afficher
      if (this.repository.length > 0) {
        this.searchField.classList.remove(searchFieldClasses.FIELD_SEARCH_INVALID);
      } else {
        this.searchField.classList.add(searchFieldClasses.FIELD_SEARCH_INVALID);
      }

      // On affiche au maximum 7 résultats
      this.maxLinesNumber = Math.min(this.repository.length, maxLinesNumber);

      // On masque le bloc "Helper text"
      this.checkHelperText(true);

      // On ajoute les lignes de résultats
      this.repository.slice(0, this.maxLinesNumber).forEach((line, index) => {
        this.addNewResultLine(line, index);
      });

      // Ajout du nombre de résultats aux infos de l'accessibilité
      this.accessibility.innerHTML = this.maxLinesNumber + accessibilityLabels.results;
      this.searchField.setAttribute("aria-expanded", "true");
      this.navigateArrows();
    } else {

      // On affiche le bloc "Helper text"
      this.checkHelperText(false);
    }
  }

  /**
   * Actions sur les flèches haut et bas
   */
  navigateArrows() {
    if (event.code === searchFieldEventsCode.UP) {
      event.preventDefault();
      if (this.selectedResult > -1) {
        this.selectedResult = this.selectedResult - 1;
      }
      this.focusOnProposition();
    }
    if (event.code === searchFieldEventsCode.DOWN && this.selectedResult < this.maxLinesNumber - 1) {
      event.preventDefault();
      this.selectedResult = this.selectedResult + 1;
      this.focusOnProposition();
    }
  }

  /**
   * Met le focus sur la ligne de résultat correspondant à la flèche clavier haut ou bas
   */
  focusOnProposition() {
    this.searchFieldResultsLi = this.searchFieldResults.querySelectorAll(".result-line");

    // on reinitialise tous les resultats
    for (let j = 0; j < this.searchFieldResultsLi.length; j++) {
      this.searchFieldResultsLi[ j ].style.border = "none";
      this.searchFieldResultsLi[ j ].setAttribute(searchFieldAttributes.ARIA_SELECTED, "false");
    }

    // on met à jour le focus sur le resultat choisit par l'utilisateur
    if (this.selectedResult < 0 ) {
      this.searchField.focus();
    } else if (this.selectedResult >= 0 && this.searchFieldResultsLi.length > 0) {
      this.searchFieldResultsLi[ this.selectedResult ].style.border = "2px solid #f16e00";
      this.searchFieldResultsLi[ this.selectedResult ].setAttribute(searchFieldAttributes.ARIA_SELECTED, "true");
      this.searchFieldResultsLi[ this.selectedResult ].focus();
    }
  }

  /**
   * Ajoute une nouvelle ligne dans le champ de résultats
   * @param {*} lineToDisplay - Ligne de résultat à afficher
   * @param {*} lineIndex - Position de la ligne de résultat
   */
  addNewResultLine(lineToDisplay, lineIndex) {

    let newResultLine = "";
    if (this.isCountrySearch === "true") {
      newResultLine = this.createNewCountryResultLine(lineToDisplay, lineIndex);
    } else {
      newResultLine = this.createNewResultLine(lineToDisplay, lineIndex);
    }
    this.searchFieldResults.appendChild(newResultLine);

    // On met des listners pour mettre à jour le champ de recherche avec la valeur sélectionnée (click souris + pression touches 'Entrée' ou 'Espace')
    newResultLine.addEventListener(searchFieldEventsListener.CLICK, () => this.addSeletedResult(newResultLine));
    newResultLine.addEventListener(searchFieldEventsListener.KEYDOWN, (event) => {

      // On regarde si utilisateur utilise touches : validation
      if (event.code === searchFieldEventsCode.SPACE || event.code === searchFieldEventsCode.ENTER) {
        this.addSeletedResult(newResultLine);
        this.searchField.focus();
      }

      // On regarde si utilisateur utilise touches : haut/bas
      this.navigateArrows();

      // On regarde si utilisateur utilise touches : droite/gauche
      if (event.code === searchFieldEventsCode.LEFT || event.code === searchFieldEventsCode.RIGHT) {

        // on reinitialise tous les resultats
        for (let j = 0; j < this.searchFieldResultsLi.length; j++) {
          this.searchFieldResultsLi[ j ].style.border = "none";
          this.searchFieldResultsLi[ j ].setAttribute(searchFieldAttributes.ARIA_SELECTED, "false");
        }
        this.searchField.focus();
        this.selectedResult = -1;
      }

      // On regarde si utilisateur utilise touches : echap
      if (event.code === searchFieldEventsCode.ESCAPE) {
        this.searchFieldResults.innerHTML = "";
        this.searchField.focus();
        this.selectedResult = -1;
      }
    });
  }

  /**
   * Crée une nouvelle ligne de résultat pour une recherche basique
   * @param {string} lineToDisplay - Ligne de résultat à afficher
   * @param {*} lineIndex - Position de la ligne de résultat
   */
  createNewResultLine(lineToDisplay, lineIndex) {

    const resultLine = this.initResultLine(lineIndex);

    // Création de l'élément contenant le début de la ligne de résultat
    const resultLineStart = this.initResultLineStart(lineToDisplay);

    // Création de l'élément contenant la fin de la ligne de résultat
    const resultLineEnd = this.initResultLineEnd(resultLineStart, lineToDisplay);

    // On construit la ligne de résultat
    resultLine.appendChild(resultLineStart);
    resultLine.appendChild(resultLineEnd);

    return resultLine;
  }

  /**
   * Crée une nouvelle ligne de résultat pour une recherche de pays
   * @param {object} lineToDisplay - Ligne de résultat à afficher
   * @param {*} lineIndex - Position de la ligne de résultat
   */
  createNewCountryResultLine(lineToDisplay, lineIndex) {
    const country = lineToDisplay.country;
    const countryIcon = lineToDisplay.icon;
    const resultLine = this.initResultLine(lineIndex);

    // Création de l'élement contenant le drapeau
    const resultLineFlag = this.initFlagElement(countryIcon);

    // Création de l'élément contenant le début de la ligne de résultat
    const resultLineStart = this.initResultLineStart(country);

    // Création de l'élément contenant la fin de la ligne de résultat
    const resultLineEnd = this.initResultLineEnd(resultLineStart, country);

    // On construit la ligne de résultat
    resultLine.appendChild(resultLineFlag);
    resultLine.appendChild(resultLineStart);
    resultLine.appendChild(resultLineEnd);

    return resultLine;
  }

  /**
   * Initialise une ligne de résultat
   * @param {*} lineIndex - Position de la ligne de résultat
   */
  initResultLine(lineIndex) {

    const resultLine = document.createElement(searchFieldElements.RESULT_LINE);
    resultLine.classList.add(searchFieldClasses.RESULT_LINE);
    resultLine.setAttribute(searchFieldAttributes.TABINDEX, searchFieldAttributes.TABINDEX_NEGATIVE_VALUE);
    resultLine.setAttribute(searchFieldAttributes.ROLE, searchFieldAttributes.ROLE_OPTION);
    resultLine.setAttribute(searchFieldAttributes.ARIA_POSINSET, (lineIndex + 1).toString());
    resultLine.setAttribute(searchFieldAttributes.ARIA_SETSIZE, this.maxLinesNumber.toString());
    let ariaSelectedValue = false;
    if (this.searchField.value === resultLine.innerText) {
      ariaSelectedValue = true;
    }
    resultLine.setAttribute(searchFieldAttributes.ARIA_SELECTED, ariaSelectedValue);

    return resultLine;
  }

  /**
   * Initialise le début d'une ligne de résultat (partie correspondant à la valeur saisie dans le champ de recherche)
   * @param {string} lineToDisplay - Ligne de résultat à afficher
   */
  initResultLineStart(lineToDisplay) {
    const resultLineStart = document.createElement(searchFieldElements.SPAN);
    resultLineStart.innerHTML = lineToDisplay.substring(0, this.searchField.value.length);

    return resultLineStart;
  }

  /**
   * Initialise la fin d'une ligne de résultat (on affiche en gras le reste de la ligne correspondant à la recherche)
   * @param {HTMLElement} resultLineStart - Début de la ligne de résultat
   * @param {string} lineToDisplay - Ligne de résultat à afficher
   */
  initResultLineEnd(resultLineStart, lineToDisplay) {
    const resultLineEnd = document.createElement(searchFieldElements.SPAN);
    resultLineEnd.innerHTML = lineToDisplay.substring(resultLineStart.innerHTML.length);
    resultLineEnd.classList.add(searchFieldClasses.BOLD);

    return resultLineEnd;
  }

  /**
   * Initialise un élément drapeau pour la recherche de pays
   * @param {string} countryIcon - Icône du pays
   */
  initFlagElement(countryIcon) {
    const flagElement = document.createElement(searchFieldElements.SPAN);
    flagElement.classList.add(searchFieldClasses.FLAG_ICON);
    flagElement.classList.add(countryIcon);
    flagElement.classList.add(searchFieldClasses.DISPLAY_INLINE_BLOCK);
    flagElement.classList.add(searchFieldClasses.MARGIN_RIGHT_1);

    return flagElement;
  }

  /**
   * Filtre les pays par rapport à la valeur saisie dans le champ de recherche
   */
  getFilteredListOfCountries() {

    // hack IE pour supporter startsWith
    if (!String.prototype.startsWith) {
      String.prototype.startsWith = function(searchString, position) {
        position = position || 0;
        return this.indexOf(searchString, position) === position;
      };
    }

    return countriesRepository.filter(line => line.country.toUpperCase()
      .substring(0, this.searchField.value.length)
      .startsWith(this.searchField.value.toUpperCase()));
  }

  /**
   * Affiche ou cache l'élément 'Helper Text'
   * @param {boolean} hideHelperText - True si on veut cacher
   */
  checkHelperText(hideHelperText) {

    // hack IE pour supporter from
    if (!Array.from) {
      Array.from = ( function () {
        var toStr = Object.prototype.toString;
        var isCallable = function (fn) {
          return typeof fn === "function" || toStr.call(fn) === "[ object Function ]";
        };
        var toInteger = function (value) {
          var number = Number(value);
          if (isNaN(number)) {
            return 0;
          }
          if (number === 0 || !isFinite(number)) {
            return number;
          }
          return (number > 0 ? 1 : -1) * Math.floor(Math.abs(number));
        };
        var maxSafeInteger = Math.pow(2, 53) - 1;
        var toLength = function (value) {
          var len = toInteger(value);
          return Math.min(Math.max(len, 0), maxSafeInteger);
        };

        // The length property of the from method is 1.
        return function from(arrayLike/*, mapFn, thisArg */) {

          // 1. Let C be the this value.
          var C = this;

          // 2. Let items be ToObject(arrayLike).
          var items = Object(arrayLike);

          // 3. ReturnIfAbrupt(items).
          if (arrayLike == null) {
            throw new TypeError("Array.from requires an array-like object - not null or undefined");
          }

          // 4. If mapfn is undefined, then let mapping be false.
          var mapFn = arguments.length > 1 ? arguments[ 1 ] : void undefined;
          var T;
          if (typeof mapFn !== "undefined") {

            // 5. else
            // 5. a If IsCallable(mapfn) is false, throw a TypeError exception.
            if (!isCallable(mapFn)) {
              throw new TypeError("Array.from: when provided, the second argument must be a function");
            }

            // 5. b. If thisArg was supplied, let T be thisArg; else let T be undefined.
            if (arguments.length > 2) {
              T = arguments[ 2 ];
            }
          }

          // 10. Let lenValue be Get(items, "length").
          // 11. Let len be ToLength(lenValue).
          var len = toLength(items.length);

          // 13. If IsConstructor(C) is true, then
          // 13. a. Let A be the result of calling the [[Construct]] internal method of C with an argument list containing the single item len.
          // 14. a. Else, Let A be ArrayCreate(len).
          var A = isCallable(C) ? Object(new C(len)) : new Array(len);

          // 16. Let k be 0.
          var k = 0;

          // 17. Repeat, while k < len… (also steps a - h)
          var kValue;
          while (k < len) {
            kValue = items[ k ];
            if (mapFn) {
              A[ k ] = typeof T === "undefined" ? mapFn(kValue, k) : mapFn.call(T, kValue, k);
            } else {
              A[ k ] = kValue;
            }
            k += 1;
          }

          // 18. Let putStatus be Put(A, "length", len, true).
          A.length = len;

          // 20. Return A.
          return A;
        };
      })();
    }

    const searchFieldParent = this.searchFieldResults.parentElement;
    const searchFieldParentChildren = searchFieldParent.children;
    Array.from(searchFieldParentChildren).forEach((child) => {
      if (child.classList.contains(searchFieldClasses.FORM_CONTROL_HELP)) {
        if (hideHelperText) {
          child.classList.add(searchFieldClasses.DISPLAY_NONE);
        } else {
          child.classList.remove(searchFieldClasses.DISPLAY_NONE);
        }
      }
    });
  }

  /**
   * Ajoute la valeur sélectionnée dans le champ de recherche
   * @param {HTMLElement} resultLine - Ligne de résultat
   */
  addSeletedResult(resultLine) {
    this.searchField.value = resultLine.innerText;

    // On supprime les résultats affichés précédemment
    this.searchFieldResults.innerHTML = "";

    // on met à jour l'accessibilité
    this.searchField.setAttribute("aria-expanded", "false");

    if (this.isCountrySearch === "true") {

      // On affiche l'icône du drapeau à côté du pays sélectionné
      const iconElement = resultLine.firstChild;
      this.searchFieldResults.parentNode.insertBefore(iconElement, this.searchFieldResults);
      this.searchField.classList.add(searchFieldClasses.FIELD_SEARCH_COUNTRY_SELECTED);
      iconElement.classList.add(searchFieldClasses.FIELD_SEARCH_COUNTRY_SELECTED);
    }
  }

  /**
   * Supprime le drapeau ajouté dans le champ de recherche lors d'une première sélection de pays
   */
  searchCountryFieldReinitilisation(event) {
    if (this.isCountrySearch === "true" && (event.code === searchFieldEventsCode.BACKSPACE_CODE ||
      event.code === searchFieldEventsCode.DELETE_CODE  ||
      event.code === "")) {
      const searchFieldFlagElement = document.querySelector(searchFieldSelectors.FIELD_SEARCH_COUNTRY_SELECTED);
      if (searchFieldFlagElement !== null) {
        searchFieldFlagElement.remove();
        this.searchField.classList.remove(searchFieldClasses.FIELD_SEARCH_COUNTRY_SELECTED);
      }
    }
  }
}


// rattachement au contexte window pour pouvoir l'utiliser en dehors du JS
window.SearchField = SearchField;

export default SearchField;
